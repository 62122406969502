import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 4; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/21-sep-5/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function AlumniConnect2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">ALUMNI CONNECT SESSION</h1>

        <p>
          Our journey may have taken us far and wide,but our hearts forever
          belong to the place where it all began. In order to inspire the young
          minds, Chinmaya Vidyalaya, New Delhi, conducted Alumni Connect Session
          for classes IX-XII on 22nd August,2023. The session was highly
          inspiring and enriching for the students. <br />
          <br />
          Dr Riddhi Gulati, the CEO of Aesthetics, shared her successful
          professional journey of hard work and determination. She answered the
          questions put forth by the students of classes IX-XII and motivated
          the students to follow their dreams through consistent efforts and
          sincere hard work.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
